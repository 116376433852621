import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faVolumeHigh, faChevronLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Avatar_1 from '../Images/Avatar_1.png';
import Avatar_2 from '../Images/Avatar_2.png';
import { useAuth } from '../Services/AuthContext';

function Chat() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
    const { userId } = useAuth();
    const messagesEndRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 950);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Mock data for users
        const mockUsers = [
            { id: 1, firstname: 'John', lastname: 'Doe', sex: 'male', last_message: 'Hello!', last_message_time: new Date().toISOString(), unread_count: 2 },
            { id: 2, firstname: 'Jane', lastname: 'Smith', sex: 'female', last_message: 'Hi there!', last_message_time: new Date().toISOString(), unread_count: 0 }
        ];
        setUsers(mockUsers);
        if (!isMobile && mockUsers.length > 0) {
            selectUser(mockUsers[0], true);
        }
    }, [isMobile]);

    const selectUser = (user, initialLoad = false) => {
        setSelectedUser(user);
        fetchMessages(user.id, initialLoad);
        if (isMobile && !initialLoad) {
            document.querySelector('.background-chat').classList.add('open-chat');
        }
    };

    const closeChat = () => {
        if (isMobile) {
            const backgroundChat = document.querySelector('.background-chat');
            backgroundChat.classList.add('closing-chat');
            setTimeout(() => {
                backgroundChat.classList.remove('open-chat', 'closing-chat');
                setSelectedUser(null);
            }, 300);
        } else {
            setSelectedUser(null);
        }
    };

    const fetchMessages = (targetId, initialLoad = false) => {
        // Mock data for messages
        const mockMessages = [
            { user_id: userId, target_id: targetId, messages: 'Hello!', time: new Date().toISOString() },
            { user_id: targetId, target_id: userId, messages: 'Hi!', time: new Date().toISOString() }
        ];
        setMessages(mockMessages);
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (initialLoad) {
            document.querySelector('.background-chat').classList.add('open-chat');
        }
    };

    const sendMessage = () => {
        if (newMessage.trim() !== '' && selectedUser) {
            const currentTime = new Date().toISOString();
            const newMessageObject = {
                user_id: userId,
                target_id: selectedUser.id,
                messages: newMessage,
                time: currentTime
            };
            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages, newMessageObject];
                return updatedMessages.sort((a, b) => new Date(a.time) - new Date(b.time));
            });

            setUsers(prevUsers => {
                const updatedUsers = prevUsers.map(user => {
                    if (user.id === selectedUser.id) {
                        return { ...user, last_message_time: currentTime };
                    }
                    return user;
                }).sort((a, b) => new Date(b.last_message_time) - new Date(a.last_message_time));
                return updatedUsers;
            });

            setNewMessage('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const formatDateHeader = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(date).toLocaleDateString('fr-FR', options);
    };

    const shouldShowDateHeader = (currentMessage, previousMessage) => {
        const currentDate = new Date(currentMessage.time).toDateString();
        const previousDate = previousMessage ? new Date(previousMessage.time).toDateString() : null;
        return currentDate !== previousDate;
    };

    const getLastMessagePreview = (lastMessage) => {
        if (lastMessage) {
            const preview = lastMessage.substring(0, 25);
            return preview.length < lastMessage.length ? preview + '...' : preview;
        }
        return 'Commencer une discussion';
    };

    const ReturnHome = () => {
        navigate("/accueil");
    };

    return (
        <div className={`background-chat ${isMobile ? 'mobile-view' : ''}`}>
            <div className={`container-users-chat ${selectedUser && isMobile ? 'hidden' : ''}`}>
                <div className='users-chat-general'>
                    <div className='container-icon'>
                        <FontAwesomeIcon className='icon' icon={faVolumeHigh} />
                    </div>
                    <div className='container-text'>
                        <h3>Discussion générale</h3>
                        <p>Commencer une discussion</p>
                    </div>
                </div>
                {Array.isArray(users) && users.map(user => (
                    <div 
                        key={user.id} 
                        className={`users-chat ${selectedUser && selectedUser.id === user.id ? 'selected' : ''}`}
                        onClick={() => selectUser(user)}
                    >
                        <div className='container-img'>
                            <img src={user.sex === 'male' ? Avatar_1 : Avatar_2} alt={`Avatar de ${user.firstname} ${user.lastname}`} />
                            {user.unread_count > 0 && <span className='notification'>{user.unread_count}</span>}
                        </div>
                        <div className='container-text'>
                            <h3>{user.firstname} {user.lastname}</h3>
                            <p>{getLastMessagePreview(user.last_message)}</p>
                        </div>
                    </div>
                ))}

                <div className='container-btn-exit'>
                    <FontAwesomeIcon onClick={ReturnHome} className='icon-back' icon={faArrowLeft} />
                </div>
            </div>
            {(!isMobile || selectedUser) && (
                <div className='container-chat'>
                    <div className='header-chat'>
                        {selectedUser && (
                            <>
                                <button className='back-chat' onClick={closeChat}><FontAwesomeIcon icon={faChevronLeft} /></button>
                                <h1>{selectedUser.firstname} {selectedUser.lastname}</h1>
                            </>
                        )}
                    </div>
                    <div className='messages-list'>
                        {Array.isArray(messages) && messages.map((message, index) => (
                            <React.Fragment key={index}>
                                {shouldShowDateHeader(message, messages[index - 1]) && (
                                    <div className='container-date'>
                                        <p>{formatDateHeader(message.time)}</p>
                                    </div>
                                )}
                                <div
                                    className={`container-text-chat-${message.user_id === userId ? 'self' : 'target'}`}
                                >
                                    <div className="chat">
                                        <p>{message.messages}</p>
                                        <div className='container-hours'>
                                            <p>{new Date(message.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    {selectedUser && (
                        <div className='container-input'>
                            <input 
                                type="text" 
                                placeholder="Écrivez un message..." 
                                value={newMessage} 
                                onChange={(e) => setNewMessage(e.target.value)} 
                                onKeyPress={handleKeyPress} 
                            />
                            <button onClick={sendMessage}>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Chat;
