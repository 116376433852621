import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faUser, faUserGroup, faChevronLeft, faChevronRight, faMagnifyingGlass, faClockRotateLeft, faPlay, faStop, faArrowLeft, faClock } from '@fortawesome/free-solid-svg-icons';
import DialogTasks from './DialogTasks/DialogTasks';  
import './Tasks.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Services/AuthContext';

function Tasks() {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]); 
    const [currentUserIndex, setCurrentUserIndex] = useState(0);
    const [selectedTasks, setSelectedTasks] = useState([]); 
    const [selectedTask, setSelectedTask] = useState(null);  
    const [isDialogOpen, setIsDialogOpen] = useState(false); 
    const [isNewTask, setIsNewTask] = useState(false);  
    const [statusFilter, setStatusFilter] = useState('all'); 
    const { userId, permission } = useAuth();

    const fetchTasks = async (userId) => {
        try {
            const response = userId === undefined ? await fetch(`/api/task`) : await fetch(`/api/user/${userId}/task`);
            const data = await response.json();
            setTasks(data);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await fetch('/api/user');
            const data = await response.json();
            data.unshift({ firstname: 'All', lastname: 'Users' });
            setUsers(data);
            await fetchTasks(users[currentUserIndex]?.id);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchMyTasks = async () => {
        try {
            const response = await fetch('/api/profile/task');
            const data = await response.json();
            setTasks(data);
        }
        catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    useEffect(() => {
        if (permission === 'admin') {
            fetchUsers();
        } else {
            fetchMyTasks();
        }
        // eslint-disable-next-line
    }, [userId, permission, currentUserIndex]);

    const handleBoxClick = (task) => {
        if (permission === 'admin') {
            if (selectedTasks.includes(task.id)) {
                setSelectedTasks(selectedTasks.filter(id => id !== task.id));
            } else {
                setSelectedTasks([...selectedTasks, task.id]);
            }
        }
    };

    const handleViewMore = (event, task) => {
        event.stopPropagation(); 
        setSelectedTask(task);
        setIsNewTask(false);  
        setIsDialogOpen(true);
    };

    const handleDeleteSelectedTasks = () => {
        if (selectedTasks.length > 0) {
            selectedTasks.forEach(task => {
                fetch('/api/task/' + task, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        console.error('Error deleting task:', response.status);
                        return;
                    }
                    setTasks(tasks.filter(task => !selectedTasks.includes(task.id)));
                    setSelectedTasks([]);
                })
                .catch(error => {
                    console.error('Error deleting tasks:', error);
                });
            });
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedTask(null);
        if (permission === 'admin') {
            fetchUsers();
        } else {
            fetchMyTasks();
        }
    };

    const handleTaskCompleted = () => {
        setIsDialogOpen(false);
        setSelectedTask(null);
        if (permission === 'admin') {
            fetchUsers();
        } else {
            fetchMyTasks();
        }
    };

    const handleCreateTask = () => {
        setSelectedTask({ title: '', description: '', priority: 1 }); 
        setIsNewTask(true);
        setIsDialogOpen(true);
    };

    const handlePreviousUser = () => {
        setCurrentUserIndex((prevIndex) => 
            prevIndex === 0 ? users.length - 1 : prevIndex - 1
        );
    };

    const handleNextUser = () => {
        setCurrentUserIndex((prevIndex) => 
            prevIndex === users.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleStatusFilterChange = (filter) => {
        setStatusFilter(filter);
    };

    const handleBackClick = () => {
        navigate('/accueil');
    };

    const startTimer = (taskId) => {
        fetch(`/api/task/${taskId}/start`).then(response => {
            if (!response.ok) {
                console.error('Error starting timer:', response.status);
                return;
            }

            setTasks(tasks.map(task => 
                task.id === taskId ? { ...task, status_time: 1 } : task
            ));
        });
    };
    
    const stopTimer = (taskId) => {
        fetch(`/api/task/${taskId}/stop`).then(response => {
            if (!response.ok) {
                console.error('Error stoping timer:', response.status);
                return;
            }
            setTasks(tasks.map(task => 
                task.id === taskId ? { ...task, status_time: 0 } : task
            ));
        });
    };

    const filteredTasks = tasks.filter(task => {
        return statusFilter === 'all' || task.status === statusFilter;
    });

    return (
        <div className='background-tasks'>
            <div className='container-filter'>
                <div className='filter-status'>
                    <span 
                        className={statusFilter === 'all' ? 'active' : ''}
                        onClick={() => handleStatusFilterChange('all')}
                    >
                        Toutes
                    </span>
                    <span 
                        className={statusFilter === 'TODO' ? 'active' : ''}
                        onClick={() => handleStatusFilterChange('TODO')}
                    >
                        À faire
                    </span>
                    <span 
                        className={statusFilter === 'IN_PROGRESS' ? 'active' : ''}
                        onClick={() => handleStatusFilterChange('IN_PROGRESS')}
                    >
                        En cours
                    </span>
                    <span 
                        className={statusFilter === 'DONE' ? 'active' : ''}
                        onClick={() => handleStatusFilterChange('DONE')}
                    >
                        Terminée
                    </span>
                </div>
                {permission === 'admin' && users.length > 0 && (
                    <div className='filter-name'>
                        <FontAwesomeIcon icon={faChevronLeft} className='arrow' onClick={handlePreviousUser} />
                        <span>{users[currentUserIndex]?.firstname} {users[currentUserIndex]?.lastname}</span>
                        <FontAwesomeIcon icon={faChevronRight} className='arrow' onClick={handleNextUser} />
                    </div>
                )}

                <br />
            </div>
            <div className='container-box'>
                {Array.isArray(filteredTasks) && filteredTasks.length > 0 ? (
                    filteredTasks.map((task, index) => {
                        const isGroupedTask = task.assignees && task.assignees.length > 1;
                        return (
                            <div
                                key={index}
                                className={`box ${selectedTasks.includes(task.id) ? 'selected' : ''}`}
                                onClick={() => handleBoxClick(task)}
                            >
                                <h1>{task.title}</h1>
                                {task.due_date !== "null" && (
                                    <div className='due-date taskItem'>
                                        <FontAwesomeIcon icon={faClockRotateLeft} />
                                        <span>{new Date(task.due_date).toLocaleDateString()}</span>
                                    </div>
                                )}
                                {task?.hours !== undefined && (
                                    <div className='hours taskItem'>
                                        <FontAwesomeIcon icon={faClock} />
                                        <span>{task.hours} h</span>
                                    </div>
                                )}
                                <div className='container-info'>
                                    <span>
                                        <FontAwesomeIcon icon={isGroupedTask ? faUserGroup : faUser} />
                                    </span>
                                    <span style={{
                                        color: task.priority === 5 ? '#f35b51' :
                                            task.priority === 4 ? '#f3a451' :
                                            task.priority === 3 ? '#e5df20' :
                                            task.priority === 2 ? '#a0dd1d' :
                                            '#0cb55e'
                                    }}>
                                        {task.priority}
                                    </span>
                                    {(permission !== 'admin' || users[currentUserIndex].id === userId) && (
                                        <span onClick={() => task.status_time === 1 ? stopTimer(task.id) : startTimer(task.id)}>
                                            {task.status_time === 1 ? (
                                                <FontAwesomeIcon style={{ color: "#da5050", cursor: "pointer" }} icon={faStop} />
                                            ) : (
                                                <FontAwesomeIcon style={{ color: "#0cb55e", cursor: "pointer" }} icon={faPlay} />
                                            )}
                                        </span>
                                    )}
                                </div>
                                <div className='container-btn'>
                                    <button onClick={(event) => handleViewMore(event, task)}>
                                        <FontAwesomeIcon className='icon' icon={faMagnifyingGlass} />
                                        Détails
                                    </button>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <h3>Aucune tâche à afficher.</h3>
                )}
            </div>
            
            <div className='footer-tools'>
                <FontAwesomeIcon onClick={handleBackClick} className='icon-back' icon={faArrowLeft} />
                <div className='container-tools'>
                    <button onClick={handleCreateTask}><FontAwesomeIcon icon={faPlus} /></button> 
                    {permission === 'admin' && selectedTasks.length > 0 && (
                        <button
                            onClick={handleDeleteSelectedTasks}
                            className={selectedTasks.length > 0 ? 'active' : ''}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    )}
                </div>
            </div>

            {isDialogOpen && (
                <DialogTasks 
                    task={selectedTask} 
                    onClose={handleCloseDialog} 
                    onTaskCompleted={handleTaskCompleted}  
                    isNewTask={isNewTask} 
                />
            )}
        </div>
    );
}

export default Tasks;
