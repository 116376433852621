import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../Services/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faClock } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Images/Logo.svg';
import Schedule from '../Schedule/Schedule';
import './Header.css';

function Header() {
    const location = useLocation();
    const { logout, isAuthenticated, loading } = useAuth(); 
    const [isScheduleOpen, setIsScheduleOpen] = useState(false);

    const handleLogoClick = () => {
        if (['/discussion', '/tache', '/gestion', '/rapport', '/horaire', '/settings'].includes(location.pathname)) {
        }
    };

    const handleLogoutClick = () => {
        fetch('/api/auth/logout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        }).then(() => {
            logout();
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const handleScheduleClick = () => {
        setIsScheduleOpen((prevState) => !prevState);
    };

    const handleCloseSchedule = () => {
        setIsScheduleOpen(false);
    };

    if (loading) {
        return null;
    }

    return (
        <>
            {!['/tache', '/discussion', '/gestion', '/rapport', '/horaire', '/settings'].includes(location.pathname) && (
                <header>
                    <span className="container-logo" onClick={handleLogoClick}>
                        <img className="logo" src={Logo} alt="Logo LogixMedia" />
                    </span>

                    {isAuthenticated && (
                        <>
                            <FontAwesomeIcon
                                onClick={handleScheduleClick}
                                className="schedule"
                                icon={faClock}
                            />
                            <FontAwesomeIcon
                                onClick={handleLogoutClick}
                                className="disconnect"
                                icon={faRightFromBracket}
                            />
                        </>
                    )}
                </header>
            )}

            <AnimatePresence>
                {isScheduleOpen && <Schedule onClose={handleCloseSchedule} />}
            </AnimatePresence>
        </>
    );
}

export default Header;
