import React, { useState, useEffect } from 'react';
import './DialogTasks.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Services/AuthContext';
import Alert from '../../Alert/Alert';

function Tag({ label, onRemove }) {
    return (
        <div className="tag">
            {label}
            <span onClick={onRemove} className="tag-close">&times;</span>
        </div>
    );
}

function SubTask({ subTask, onRemove }) {
    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + "...";
        }
        return title;
    };

    return (
        <div className="sub-task" style={{ fontSize: '0.9em', padding: '5px' }}>
            <div className="sub-task-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {truncateTitle(subTask.title, 25)}
                <span onClick={onRemove} className="sub-task-close" style={{ marginLeft: '10px', cursor: 'pointer' }}>&times;</span>
                <select
                    defaultValue={subTask.status}
                    className='sub-task-status'
                    onChange={(e) => subTask.status = e.target.value}
                >
                    <option value="TODO">À faire</option>
                    <option value="IN_PROGRESS">En cours</option>
                    <option value="DONE">Terminée</option>
                </select>
            </div>
        </div>
    );
}

function DialogTasks({ task, onClose, onTaskCompleted, isNewTask = false }) {
    const { permission } = useAuth();
    const [isClosing, setIsClosing] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [taskData, setTaskData] = useState({
        id: task?.id || null,
        title: task?.title || '',
        description: isNewTask ? '' : (task?.description || 'Aucune information fournie.'),
        priority: task?.priority || 1,
        assignees: task?.assignees || [],
        due_date: task?.due_date || '',
        status: task?.status || 'TODO' // Add status field
    });
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [subTasks, setSubTasks] = useState([]);
    const [newSubTaskTitle, setNewSubTaskTitle] = useState('');

    const fetchUsers = async () => {
        try {
            const response = await fetch('/api/user');
            if (!response.ok) {
                return;
            }
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            setAlertMessage({ message: error.message, type: 'error' });
        }
    };

    useEffect(() => {
        if (permission === 'admin') fetchUsers();

        setSubTasks(task.subtasks || []);
    }, [isNewTask, task, permission]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            setIsClosing(false);
        }, 250);
    };

    const handleSaveTask = async () => {
        if (taskData.title.trim() === '') {
            setAlertMessage({ message: 'Le titre ne peut pas être vide.', type: 'error' });
            return;
        }

        if (taskData.assignees.length === 0 && permission === 'admin') {
            setAlertMessage({ message: 'Vous devez sélectionner au moins un utilisateur pour la tâche.', type: 'error' });
            return;
        }

        var assignees = [];

        taskData.assignees.forEach(assignee => {
            assignees.push(assignee.id);
        });

        var forms = {
            title: taskData.title,
            description: taskData.description,
            priority: taskData.priority,
            assignees: assignees,
            due_date: taskData.due_date === '' || taskData.due_date === 'null' ? '' : taskData.due_date.split('T').join(' '),
            subtask: subTasks
        };

        if (!isNewTask) {
            forms.status = taskData.status; // Include status only if it's not a new task
        }

        try {
            if (!isNewTask) {
                const response = await fetch('/api/task/' + taskData.id, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(forms),
                });

                if (!response.ok) {
                    throw new Error('Failed to save task');
                }
            } else {
                const response = await fetch('/api/task', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(forms),
                });

                if (!response.ok) {
                    throw new Error('Failed to save task');
                }
            }

            onTaskCompleted();
        } catch (error) {
            setAlertMessage({ message: error.message, type: 'error' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTaskData({ ...taskData, [name]: value });
    };

    const handleUserSelect = (e) => {
        const selectedUserId = e.target.value;
        if (!selectedUserId) {
            return;
        }
        const user = users.find(user => user.id.toString() === selectedUserId);

        try {
            taskData.assignees.forEach((task) => {
                if (task.id.toString() === selectedUserId) {
                    throw new Error('Cet utilisateur a déjà été ajouté.');
                }
            });
        } catch (error) {
            setAlertMessage({ message: error.message, type: 'error' });
            return;
        }

        setTaskData(prevState => ({
            ...prevState,
            assignees: [...prevState.assignees, user]
        }));
        setSelectedUser("");
    };

    const handleRemoveTag = (user) => {
        setTaskData(prevState => ({
            ...prevState,
            assignees: prevState.assignees.filter(userFilter => userFilter !== user)
        }));
    };

    const handleAddSubTask = () => {
        if (newSubTaskTitle.trim() !== '') {
            setSubTasks([...subTasks, { title: newSubTaskTitle, status: "TODO" }]);
            setNewSubTaskTitle('');
        }
    };

    const handleRemoveSubTask = (index) => {
        const updatedSubTasks = subTasks.filter((_, i) => i !== index);
        setSubTasks(updatedSubTasks);
    };

    useEffect(() => {
        if (alertMessage.message) {
            const timer = setTimeout(() => {
                setAlertMessage({ message: '', type: '' });
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [alertMessage]);

    return (
        <div className={`background-dialogtasks ${task ? (isClosing ? 'closing' : 'open') : ''}`}>
            <div className='dialogtasks'>
                <h1>{isNewTask ? 'Créer une nouvelle tâche' : 'Détails de la tâche'}</h1>
                {alertMessage.message && (
                    <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
                )}
                <div className='container-input-1'>
                    <div className='input-box'>
                        <label htmlFor="task-title">Titre</label>
                        <input
                            type="text"
                            id="task-title"
                            name="title"
                            value={taskData.title}
                            onChange={handleChange}
                            maxLength={55}
                        />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="task-priority">Priorité de la tâche</label>
                        <select
                            id="task-priority"
                            name="priority"
                            value={taskData.priority}
                            onChange={handleChange}
                        >
                            <option value={1}>1 - Pas urgent</option>
                            <option value={2}>2 - Peu urgent</option>
                            <option value={3}>3 - Moyennement urgent</option>
                            <option value={4}>4 - Très urgent</option>
                            <option value={5}>5 - Extrêmement urgent</option>
                        </select>
                    </div>
                </div>
                    <div className='container-input-2'>
                        {permission === 'admin' && (
                        <div className='input-box'>
                            <label htmlFor="task-assigned">Attribution de la tâche</label>
                            <>
                                <select
                                    id="task-assigned"
                                    name="assigned"
                                    value={selectedUser}
                                    onChange={handleUserSelect}
                                >
                                    <option value="">Sélectionnez un utilisateur</option>
                                    {users.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.firstname} {user.lastname}
                                        </option>
                                    ))}
                                </select>
                                <div className="tag-container">
                                    {taskData.assignees.map(user => {
                                        return (
                                            <Tag
                                                key={user.id}
                                                label={`${user.firstname} ${user.lastname}`}
                                                onRemove={() => handleRemoveTag(user)}
                                            />
                                        );
                                    })}
                                </div>
                            </>
                        </div>
                        )}
                        <div className='input-box'>
                            <label htmlFor="due_date">Date d'échéance</label>
                            <input
                                type="datetime-local"
                                id="due_date"
                                name="due_date"
                                value={taskData.due_date || ''}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                <div className="container-input-3">
                    <div className="sub-task-list">
                    </div>
                        <label htmlFor="subtask-title">Sous-tâches</label>
                        <div className="sub-task-input">
                            <input
                                type="text"
                                id="subtask-title"
                                name="subtask-title"
                                value={newSubTaskTitle}
                                onChange={(e) => setNewSubTaskTitle(e.target.value)}
                                placeholder="Nom de la sous-tâche"
                                maxLength={75}
                            />
                            <button onClick={handleAddSubTask} className="add-subtask-btn">
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                        <div className="sub-task-list">
                            {subTasks.map((subTask, index) => (
                                <SubTask
                                    key={index}
                                    subTask={subTask}
                                    onRemove={() => handleRemoveSubTask(index)}
                                />
                            ))}
                        </div>
                </div>
                {!isNewTask && (
                    <div className='container-input-5'>
                        <label htmlFor="task-status">Statut</label>
                        <select
                            id="task-status"
                            name="status"
                            value={taskData.status}
                            onChange={handleChange}
                        >
                            <option value="TODO">À faire</option>
                            <option value="IN_PROGRESS">En cours</option>
                            <option value="DONE">Terminée</option>
                        </select>
                    </div>
                )}

                <div className='container-input-4'>
                    <label htmlFor="task-description">Notes</label>
                    <textarea
                        name="description"
                        id="task-description"
                        value={taskData.description}
                        onChange={handleChange}
                    />
                </div>
                <div className='container-btn'>
                    <button onClick={handleClose}>{isNewTask ? 'Annuler' : 'Fermer'}</button>
                    {isNewTask ? (
                        <button onClick={handleSaveTask}>Créer la tâche</button>
                    ) : (
                        <button 
                            onClick={handleSaveTask} 
                        >
                            Éditer la tâche
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DialogTasks;
