import React from 'react';
import './Report.css';

function Report() {
    return (
        <div className='container-box-home'>
        </div>
    );
}

export default Report;
