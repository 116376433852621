import React, { useState, useEffect } from 'react';
import './Home.css';
import Chat from '../Images/Chat.svg';
import Management from '../Images/Management.svg';
import Task from '../Images/Task.svg';
import Setting from '../Images/Settings.svg';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAuth } from '../Services/AuthContext';

function Home() {
    const navigate = useNavigate();
    const { permission } = useAuth();

    const [isCarouselEnabled, setIsCarouselEnabled] = useState(window.innerWidth < 1150);

    useEffect(() => {
        const handleResize = () => {
            setIsCarouselEnabled(window.innerWidth < 1150);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const boxes = [
        {
            title: "Tâches",
            icon: Task,
            alt: "Icon de tâche",
            onClick: () => navigate('/tache')
        },
        false && {
            title: "Discussion",
            icon: Chat,
            alt: "Icon de discussion",
            onClick: () => navigate('/discussion')
        },
        
        permission === 'admin' && {
            title: "Gestion",
            icon: Management,
            alt: "Icon de gestion",
            onClick: () => navigate('/gestion')
        },
        {
            title: "Paramètre",
            icon: Setting,
            alt: "Icon de paramètre",
            onClick: () => navigate('/settings')
        }
    ].filter(Boolean);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <div className='background-home'>
            {isCarouselEnabled ? (
                <Slider {...settings} className='container-box-home'>
                    {boxes.map((box, index) => (
                        <div key={index}>
                            <motion.div
                                className='box'
                                onClick={box.onClick}
                                whileHover={{ y: -10 }}
                                transition={{ type: "spring", stiffness: 300, damping: 15 }}
                            >
                                <h2>{box.title}</h2>
                                <img className='icon' src={box.icon} alt={box.alt} />
                                <div className='dot'></div>
                            </motion.div>
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className='container-box-home'>
                    {boxes.map((box, index) => (
                        <motion.div
                            key={index}
                            className='box'
                            onClick={box.onClick}
                            whileHover={{ y: -10 }}
                            transition={{ type: "spring", stiffness: 300, damping: 15 }}
                        >
                            <h2>{box.title}</h2>
                            <img className='icon' src={box.icon} alt={box.alt} />
                            <div className='dot'></div>
                        </motion.div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Home;
