import React, { useState, useEffect } from 'react';
import './DialogManagement.css';
import Alert from '../../Alert/Alert';

function DialogManagement({ mode, user, onClose }) {
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const [isClosing, setIsClosing] = useState(false);
    const [baseFormData, setBaseFormData] = useState({});
    const [formData, setFormData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        gender: 'Male',
        role: 'user'
    });
    const [customGender, setCustomGender] = useState('');
    
    useEffect(() => {
        if (mode === 'edit' && user) {
            var gender = user.gender;
            if (!["Male", "Female"].includes(user.gender)) {
                gender = "Custom";
                setCustomGender(user.gender);
            }
            setBaseFormData({
                username: user.username,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                password: '',
                gender: user.gender,
                role: user.role
            });
            setFormData({
                username: user.username,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                password: '',
                gender: gender,
                role: user.role
            });
            
        }
    }, [mode, user]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 250);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        var data = {};
        if (mode === 'edit') {
            if (formData.username !== baseFormData.username) {
                data.username = formData.username;
            }
            if (formData.firstname !== baseFormData.firstname) {
                data.firstname = formData.firstname;
            }
            if (formData.lastname !== baseFormData.lastname) {
                data.lastname = formData.lastname;
            }
            if (formData.email !== baseFormData.email) {
                data.email = formData.email;
            }
            if (formData.password !== baseFormData.password) {
                data.password = formData.password;
            }
            if (formData.gender !== baseFormData.gender) {
                if (formData.gender === 'Custom') {
                    data.gender = customGender;
                } else {
                    data.gender = formData.gender;
                }
            }
            if (formData.role !== baseFormData.role) {
                data.role = formData.role;
            }

            fetch(`/api/user/${user.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then(response => {
                if (!response.ok) {
                    setAlertMessage({ message: "Erreur lors de l'éditons de l'utilisateur", type: 'error' });
                    return;
                }
                handleClose();
            })
        }else {
            data = formData;

            if (formData.gender === 'Custom') {
                data.gender = customGender;
            }

            fetch('/api/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            .then(response => {
                if (!response.ok) {
                    setAlertMessage({ message: "Erreur lors de la création de l'utilisateur", type: 'error' });
                    return;
                }
                handleClose();
            });
        }
    };

    return (
        <div className={`dialogmanagement ${isClosing ? 'closing' : ''}`}>
            {alertMessage.message && (  
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <h1>{mode === 'edit' ? 'Modification d\'utilisateur' : 'Création d\'un utilisateur'}</h1>
            <div className='input-box'>
                <label htmlFor="username">Nom d'utilisateur</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                />
            </div>
            <div className='container-name'>
                <div className='input-name'>
                    <label htmlFor="lastname">Nom</label>
                    <input 
                        type="text" 
                        id="lastname" 
                        name="lastname" 
                        value={formData.lastname} 
                        onChange={handleChange} 
                    />
                </div>
                <div className='input-name'>
                    <label htmlFor="firstname">Prénom</label>
                    <input 
                        type="text" 
                        id="firstname" 
                        name="firstname" 
                        value={formData.firstname} 
                        onChange={handleChange} 
                    />
                </div>
            </div>
            <div className='input-box'>
                <label htmlFor="email">E-mail</label>
                <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                />
            </div>
            <div className='input-box'>
                <label htmlFor="gender">Genre</label>
                <select id="gender" name="gender" value={formData.gender} onChange={handleChange}>
                    <option value="Male">Homme</option>
                    <option value="Female">Femme</option>
                    <option value="Custom">Custom</option>
                </select>
                {formData.gender === 'Custom' && (
                    <input
                        type="text"
                        id="custom-gender"
                        placeholder="Please specify"
                        value={customGender}
                        onChange={(e) => setCustomGender(e.target.value)}
                    />
                )}
            </div>
            <div className='input-box'>
                <label htmlFor="role">Permission</label>
                <select id="role" name="role" value={formData.role} onChange={handleChange}>
                    <option value="user">Utilisateur</option>
                    <option value="admin">Administrateur</option>
                </select>
            </div>

            <div className='input-box'>
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                />
            </div>

            <div className='container-btn'>
                <button onClick={handleClose}>Annuler</button>
                <button onClick={handleSubmit}>{mode === 'edit' ? 'Enregistrer' : 'Créer l\'utilisateur'}</button>
            </div>
        </div>
    );
}

export default DialogManagement;
