import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './Settings.css';
import Alert from '../Alert/Alert';

const Settings = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [customGender, setCustomGender] = useState('');
    const [baseUsername, setBaseUsername] = useState('');
    const [baseFirstname, setBaseFirstname] = useState('');
    const [baseLastname, setBaseLastname] = useState('');
    const [baseEmail, setBaseEmail] = useState('');
    const [baseGender, setBaseGender] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

    useEffect(() => {
        fetch('/api/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(async (res) => {
                var data = await res.json(); 
                if (res.status !== 200) {
                    setAlertMessage({ message: "error lors de la récupération de votre profiles", type: 'error' });
                    return;
                }
                updateField(data);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        var data = {};
        if (username !== baseUsername) data.username = username;
        if (firstname !== baseFirstname) data.firstname = firstname;
        if (lastname !== baseLastname) data.lastname = lastname;
        if (email !== baseEmail) data.email = email;
        if (gender === 'Custom') {
            if (customGender !== baseGender) data.gender = customGender
        }else {
            if (gender !== baseGender) data.gender = gender;
        }

        fetch('/api/profile', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(async (res) => {
            var data = await res.json();
            if (res.status !== 200) {
                setAlertMessage({ message: "Erreur lors de la mise a jour du profile", type: 'error' });
                return;
            }
            setAlertMessage({ message: 'Profile a été mise à jour avec succès', type: 'success' });
            updateField(data);
        }
        )
    };

    const updateField = (data) => {
        setUsername(data.username);
        setBaseUsername(data.username);
        setFirstname(data.firstname);
        setBaseFirstname(data.firstname);
        setLastname(data.lastname);
        setBaseLastname(data.lastname);
        setEmail(data.email);
        setBaseEmail(data.email);
        setBaseGender(data.gender);
        if (!["Male", "Female"].includes(data.gender)) {
            setGender('Custom');
            setCustomGender(data.gender);
        }else {
            setGender(data.gender);
        }
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
            setAlertMessage({ message: 'Veuillez remplir tous les champs', type: 'error' });
            return;
        }
        if (newPassword !== confirmPassword) {
            setAlertMessage({ message: 'Les mots de passe ne correspondent pas', type: 'error' });
            return;
        }
        fetch('/api/profile/password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ old_password: currentPassword, new_password: newPassword }),
        })
        .then(async (res) => {
            if (res.status !== 200) {
                setAlertMessage({ message: "Mot de passe incorrect", type: 'error' });
                return;
            }
            setAlertMessage({ message: 'Le mot de passe a été modifié avec succès', type: 'success' });
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            e.target.reset();
        }
        ).catch((err) => {
            setAlertMessage({ message: err.detail, type: 'error' });
        });
    };

    const handleBackClick = () => {
        navigate('/accueil');
    };

    return (
        <div className='background-settings'>
            {alertMessage.message && (  
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <div className="container-box">
                <h1>Mettre à jour votre profil</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="firstname">Prénom:</label>
                        <input
                            type="text"
                            id="firstname"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastname">Nom:</label>
                        <input
                            type="text"
                            id="lastname"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="gender">Genre:</label>
                        <select
                            id="gender"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                        >
                            <option value="Male">Homme</option>
                            <option value="Female">Femme</option>
                            <option value="Custom">Custom</option>
                        </select>
                        {gender === 'Custom' && (
                            <input
                                type="text"
                                id="custom-gender"
                                placeholder="Please specify"
                                value={customGender}
                                onChange={(e) => setCustomGender(e.target.value)}
                            />
                        )}
                    </div>
                    <button type="submit">Update</button>
                </form>
                <h1>Modifier votre mot de passe</h1>
                <form onSubmit={handlePasswordChange}>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe actuel:</label>
                        <input type="password" id="password" onChange={(e) => setCurrentPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="new-password">Nouveau mot de passe:</label>
                        <input type="password" id="new-password" onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirm-password">Confirmer le mot de passe:</label>
                        <input type="password" id="confirm-password" onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                    <button type="submit">Change</button>
                </form>
            </div>
            <div className='footer-tools'>
                <FontAwesomeIcon onClick={handleBackClick} className='icon-back' icon={faArrowLeft} />
            </div>
        </div>
    );
};

export default Settings;