import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrash, faArrowLeft, faClock } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';
import Schedule from '../Schedule/Schedule';
import DialogManagement from './DialogManagement/DialogManagement';
import { useNavigate } from 'react-router-dom';
import './Management.css';
import Avatar_1 from '../Images/Avatar_1.png';
import Avatar_2 from '../Images/Avatar_2.png';
import Alert from '../Alert/Alert';

function Management() {
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMode, setDialogMode] = useState('create');
    const [selectedUser, setSelectedUser] = useState(null);
    const [isScheduleOpen, setIsScheduleOpen] = useState(false);

    useEffect(() => {
        updateUsers();
    }, []);

    const handleBoxClick = (userId) => {
        if (selectedUserId === userId) {
            setSelectedUserId(null);
        } else {
            setSelectedUserId(userId);
        }
    };

    const getPermissionLabel = (permission) => {
        return permission === 'admin' ? 'Administrateur' : 'Utilisateur';
    };

    const openDialog = (mode) => {
        if (mode === 'edit' && selectedUserId) {
            const userToEdit = users.find(user => user.id === selectedUserId);
            setSelectedUser(userToEdit);
        } else {
            setSelectedUser(null);
        }
        setDialogMode(mode);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        updateUsers();
    };

    const updateUsers = () => {
        fetch('/api/user')
            .then(response => response.json())
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    };

    const handleBackClick = () => {
        navigate('/accueil');
    };

    const handleScheduleClick = () => {
        setIsScheduleOpen(true);
    };

    const handleCloseSchedule = () => {
        setIsScheduleOpen(false);
    };

    const handleDeleteUser = () => {
        fetch(`/api/user/${selectedUserId}`, {
            method: 'DELETE',
        })
        .then(response => {
            if (response.ok) {
                setUsers(users.filter(user => user.id !== selectedUserId));
                setSelectedUserId(null);
            } else {
                setAlertMessage({ message: 'Impossible de supprimer cette utilisateur', type: 'error' });
            }
        })
        .catch(error => {
            console.error('There was an error deleting the user!', error);
        });
    };

    return (
        <div className="background-management">
            {alertMessage.message && (  
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <div className="user-list">
                <h1>Gestion des utilisateurs</h1>
                <div className="container-box">
                    {users.map(user => (
                        <div
                            key={user.id}
                            className={`box ${selectedUserId === user.id ? 'selected' : ''}`}
                            onClick={() => handleBoxClick(user.id)}
                        >
                            <div className="container-avatar">
                                <img src={user.gender === 'female' ? Avatar_2 : Avatar_1} alt={`${user.firstname} ${user.lastname}'s avatar`} />
                            </div>
                            <div className="container-info">
                                <h2>{user.firstname} {user.lastname}</h2>
                                <p>{getPermissionLabel(user.role)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="footer-tools">
                <FontAwesomeIcon onClick={handleBackClick} className="icon-back" icon={faArrowLeft} />
                <div className="container-tools">
                    {selectedUserId && (
                        <button onClick={handleScheduleClick}>
                            <FontAwesomeIcon icon={faClock} />
                        </button>
                    )}
                    <button onClick={() => openDialog('create')}><FontAwesomeIcon icon={faPlus} /></button>
                    {selectedUserId && (
                        <button onClick={() => openDialog('edit')}><FontAwesomeIcon icon={faPen} /></button>
                    )}
                    {selectedUserId && (
                        <button onClick={handleDeleteUser}><FontAwesomeIcon icon={faTrash} /></button>
                    )}
                </div>
            </div>

            {isDialogOpen && (
                <div className={`background-dialogmanagement ${isDialogOpen ? 'open' : ''}`}>
                    <DialogManagement
                        mode={dialogMode}
                        user={selectedUser}
                        onClose={closeDialog}
                    />
                </div>
            )}

            <AnimatePresence>
                {isScheduleOpen && (
                    <Schedule
                        onClose={handleCloseSchedule}
                        selectedUserId={selectedUserId}
                    />
                )}
            </AnimatePresence>
        </div>
    );
}

export default Management;
