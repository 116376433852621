import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Todolist/Home/Home';
import Management from './Todolist/Management/Management';
import Tasks from './Todolist/Tasks/Tasks';
import Connection from './Todolist/Connection/Connection';
import Chat from './Todolist/Chat/Chat';
import Report from './Todolist/Report/Report';
import PrivateRoute from './Todolist/Services/PrivateRoute';
import Schedule from './Todolist/Schedule/Schedule';
import { AuthProvider } from './Todolist/Services/AuthContext';
import NoMatch from './Todolist/Services/NoMatch';
import Header from './Todolist/Header/Header';
import Settings from './Todolist/Settings/Settings';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Connection />} />
                    <Route path="/accueil" element={<PrivateRoute element={<Home />} />} />
                    <Route path="/tache" element={<PrivateRoute element={<Tasks />} />} />
                    <Route path="/rapport" element={<PrivateRoute element={<Report />} />} />
                    <Route path="/discussion" element={<PrivateRoute element={<Chat />} />} />
                    <Route path="/horaire" element={<PrivateRoute element={<Schedule />} />} />
                    <Route path="/gestion" element={<PrivateRoute element={<Management />} requiredPermission="admin" />} />
                    <Route path="/settings" element={<PrivateRoute element={<Settings />} />} />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
