import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../Services/AuthContext';
import './Schedule.css';

function Schedule({ onClose, selectedUserId }) {
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const { userId, permission } = useAuth();
    const [hours, setHours] = useState(0);
    const [month, setMonth] = useState(monthNames[new Date().getMonth()]);
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        fetch('/api/profile/hours?start=first day of ' + month + ' ' + year + '&end=last day of ' + month + ' ' + year)
            .then(response => response.json())
            .then(data => {
                if (typeof data === 'number') {
                    setHours(data);
                } else {
                    setHours(0);
                }
                
            })
            .catch(error => {
                console.error('Error fetching hours:', error);
            });
    }, [selectedUserId, userId, permission, month, year]);

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.3 } },
        exit: { opacity: 0, transition: { duration: 0.3 } },
    };

    const dialogVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
        exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
    };

    return (
        <motion.div
            className="background-schedule"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <motion.div
                className="dialogschedule"
                variants={dialogVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <h1>Projets et Heures</h1>
                <div className="table-container">
                    <div className="filter-container">
                        <select id="month" name="month" defaultValue={month} onChange={e => setMonth(e.target.value)}>
                            <option value="January">Janvier</option>
                            <option value="February">Février</option>
                            <option value="March">Mars</option>
                            <option value="April">Avril</option>
                            <option value="May">Mai</option>
                            <option value="June">Juin</option>
                            <option value="July">Juillet</option>
                            <option value="August">Août</option>
                            <option value="September">Septembre</option>
                            <option value="October">Octobre</option>
                            <option value="November">Novembre</option>
                            <option value="December">Décembre</option>
                        </select>
                        <select id="year" name="year" defaultValue={year} onChange={e => setYear(e.target.value)}>
                            {Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hours-worked">
                        <p>{hours} heures</p>
                    </div>
                </div>
                <div className="container-btn">
                    <button onClick={onClose}>Fermer</button>
                </div>
            </motion.div>
        </motion.div>
    );
}

export default Schedule;
