import React, { useState, useEffect } from 'react';
import './Connection.css';
import Alert from '../Alert/Alert';
import { useAuth } from '../Services/AuthContext';
import { useNavigate } from 'react-router-dom';

function Connection() {
    const { login, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/accueil');
        }
    }, [isAuthenticated, navigate]);

    const validateInput = (input) => {
        const regex = /[`~<>;:"'/[\]|{}()=+]/;
        return !regex.test(input);
    };

    const handleLogin = () => {
        if (!validateInput(username) || !validateInput(password)) {
            setAlertMessage({ message: 'Le champ contient des caractères invalides.', type: 'error' });
            return;
        }

        // Simulate successful login for demonstration purposes
        fetch('/api/auth/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Nom d\'utilisateur ou mot de passe incorrect.');
            }
        }).then((data) => {
            login(data.user.id, data.user.role);
            navigate('/accueil');
        }).catch(() => {
            setAlertMessage({ message: 'Erreur de connexion.', type: 'error' });
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className='background-connection'>
            {alertMessage.message && (  
                <Alert message={alertMessage.message} type={alertMessage.type} onClose={() => setAlertMessage({ message: '', type: '' })} />
            )}
            <div className='container-connection'>
                <h1>Connexion</h1>
                <p>Veuillez remplir les champs ci-dessous.</p>
                <div className='input-box'>
                    <label htmlFor="username">Nom d'utilisateur</label>
                    <input
                        type="text"
                        name="username"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyDown} 
                    />
                </div>
                <div className='input-box'>
                    <label htmlFor="password">Mot de passe</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown} 
                    />
                </div>
                <div className='container-btn'>
                    <button onClick={handleLogin}>Connexion</button>
                </div>
            </div>
        </div>
    );
}

export default Connection;
